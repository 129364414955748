import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/whathifi.css';

loadCSS('whathifi-site');

export const WhathifiSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default WhathifiSiteWrapper;
